@import url('https://fonts.googleapis.com/css?family=Montserrat:700|Open+Sans&display=swap');

body {
  font-size: 16px;
  background: white;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  z-index: 1;
}

.App {
  text-align: center;
  max-height: 2400px;
}

.App-header {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  top: 4em;
}
.heading-background {
  position: fixed;
  opacity: 0.05;
  font-size: 25em;
  left: -0.2em;
  top: -0.5em;
  z-index: -100;
  color: #321bc7;
}
header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
header h1 {
  font-size: 1em;
  font-weight: 400;
  padding: 2em;
  margin-right: 50em;
}
nav {
  position: fixed;
  left: 60em;
  top: 6em;
  z-index: 100;
}
nav li {
  display: inline-block;
  cursor: pointer;
}
nav li .link {
  text-decoration: none;
  color: black;
  padding: 1em;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
li .link:hover {
  opacity: 1;
}
.active {
  color: black;
  opacity: 1;
}

.header-title button {
  background: black;
  border: 0px;
  padding: 1em 3em;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}
.header-title button:hover {
  opacity: 0.8;
}
.header-title button a {
  font-family: 'Open Sans', sans-serif;
  color: white;
  text-decoration: none;
}
.header-title {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 3em;
  text-align: left;
  top: 2em;
}
.about {
  display: flex;
  flex-direction: row;
}
.about-content {
  position: relative;
  left: 15em;
}
.about-image {
  max-width: 35vw;
  max-height: 60vh;
  padding-top: 2rem;
  filter: grayscale(70%) contrast(120%);
  border-radius: 50%;
  opacity: 50%;
  transition: all ease-in-out 0.4s;
}
.about-image:hover {
  opacity: 100%;
  filter: grayscale(40%) contrast(120%);
}
.about-content h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  text-align: left;
}
.about-content p {
  font-size: 1.3em;
  text-align: left;
  width: 25em;
  padding-right: 15rem;
}
.about img {
  margin-left: 5em;
}
.work {
  position: relative;
  display: flex;
  flex-direction: column;
  left: 15em;
  top: 8em;
  align-items: flex-start;
}
.work .heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  text-align: left;
}
.work .work-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
}
.project {
  position: relative;
  width: 32em;
  height: 40em;
}
.project img {
  margin-top: 1rem;
  width: 30em;
  height: 30em;
  transition: all ease-in-out 0.3s;
}
.project-description {
  margin: 1rem;
  padding: 8rem 0;
  position: absolute;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 30em;
  height: 26em;
  background: linear-gradient (#321bc7, rgb(255, 255, 255));
  color: rgb(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.3s;
}
.project:hover .project-description {
  visibility: visible;
  opacity: 1;
}

.project:hover img {
  opacity: 0.3;
}

.contact {
  position: relative;
  top: 20em;
  left: 15em;
  text-align: left;
}
.contact h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  text-align: left;
}
.amazing-color {
  color: #321bc7;
  opacity: 1;
}

.contact .email {
  color: black;
  font-size: 2em;
}
.contact li {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 0;
  margin-right: 2.5em;
  margin-left: 0.2rem;
}
.contact li a {
  text-decoration: none;
  color: black;
}
.footer {
  position: relative;
  top: 2em;
  padding: 1em;
}
.footer a {
  text-decoration: none;
  color: #2c59fb;
}

/* SMARTPHONES PORTRAIT */
@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
  body {
    font-size: .3em;
  }
  header h1 {
    font-size: 5em;
    padding: 1em;
    margin-right: 0;
  }
  .heading-background {
    /* display: none; */
    font-size: 18em;
    margin: .5em .2em 0 .4em
  }
  nav {
    display: none;
  }
  .header-title {
    font-size: 4em;
    text-align: left;
    width: 80vw;
  }
  .header-title button {
    margin-top: 1em;
  }
  .about {
    position: relative;
    display: flex;
    top: -70em;
    justify-content: justify;
    padding-left: 4em;
    max-height: 400px;
  }
  .about img {
    display: none;
  }
  .about-content {
    left: 1em;
    top: 35em;
    max-width: 90vw;
    padding: 0  .2em;
    
  }
  .about-content p {
    font-size: 3.5em;
    text-align: left;
    width: 80vw;
    padding: 0;
  }
  .work {
    top: -80em;
    left: 1em;
    padding-left: 8em;
    justify-content: justify;
    align-items: flex-start;
  }
  .work .work-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15em;
    margin: 10em 30%;
  }
  .project img {
    width: 45em;
    height: 45em;
    padding: 1em 0 0 1.3em;
    margin: 0.5em;
  }
  .contact {
    top: -50em;
    left: 10em;
  }
  .contact h1 {
    font-size: 4em;
  }
  .contact .email {
    font-size: 3.5em;
  }
  .contact li {
    display: inline-block;
    padding: 2em;
  }
  .contact li a {
    font-size: 2.5em;
  }
  .footer {
    top: 4em;
    padding: 1em;
    font-size: 2em;
  }
}


/* iPads (portrait and landscape) ----------- */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  body {
    font-size: 10px;
  }
  header h1 {
    font-size: 5em;
    padding: 1em;
    margin-right: 0em;
  }

  nav {
    display: none;
  }
  .header-title {
    font-size: 3.6em;
    text-align: left;
    top: 1em;
    left: 1em;
  }
  .about {
    position: relative;
    display: flex;
    top: -10em;
  }
  .about img {
    display: none;
  }
  .about-content {
    left: 6em;
  }
  .about-content p {
    font-size: 3.5em;
    text-align: left;
    width: 700px;
  }

  .work {
    left: 6em;
    top: 4em;
    align-items: flex-start;
  }
  .work .work-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5em;
  }
  .project img {
    width: 30em;
    height: 30em;
  }

  .contact {
    top: 15em;
    left: 6em;
  }
  .contact h1 {
    font-size: 4em;
  }
  .contact .email {
    font-size: 3.5em;
  }

  .contact li {
    display: block;
    padding: 2em;
  }
  .contact li a {
    font-size: 2.5em;
  }
  .footer {
    top: 4em;
    padding: 1em;
    font-size: 2em;
  }
} */

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
/* Styles */

body {
  font-size: 20px;
}
/* 

  header h1 {
    font-size: 3em;
    margin-right: 25em;
  }
  nav {
    left: 70em;
    top: 5em;
    z-index: 100;
  }
  nav li {
    font-size: 2em;
  }
  .header-title {
    font-size: 4.5em;
    top: 1em;
    left: 0.3em;
  }
  .work-content {
    padding-left: 5em;
  }
  .project {
    padding: 0 3rem;
  }
  .project-description {
    padding: 10rem 5em;
    text-align: center;
    max-width: 25rem;
  }
  .about img {
    margin-left: 18em;
  }
  
  .about {
    position: relative;
    display: flex;
    top: -20em;
  }
  .about-content {
    left: 10em;
  }
  .about-content p {
    font-size: 3.5em;
    text-align: left;
    width: 25em;
  }

  .work {
    left: 10em;
    top: 4em;
    align-items: flex-start;
  }
  .work .work-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .project img {
    width: 32em;
    height: 32em;
  }

  .contact {
    top: 10em;
    left: 8em;
  }
  .contact h1 {
    font-size: 8em;
  }
  .contact .email {
    font-size: 5em;
  }

  .contact li a {
    font-size: 4em;
    padding: 1em;
  }
  .footer {
    top: 4em;
    padding: 1em;
    font-size: 4em;
  } */
}
